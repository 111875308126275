import MobileAppBar from '@/components/molecules/MobileAppBar';
import React, { useState } from 'react';
import binIcon from '@/assets/icons/bin.svg';
import budgetIcon from '@/assets/icons/budget.svg';
import editIcon from '@/assets/icons/edit.svg';
import percentageIcon from '@/assets/icons/percentage.svg';
import euroIcon from '@/assets/icons/euro.svg';
import vaultIcon from '@/assets/icons/vault.svg';
import plusIcon from '@/assets/icons/plus.svg';
import buildingIcon from '@/assets/icons/building.svg';
import upAndDownArrowIcon from '@/assets/icons/upAndDownArrow.svg';
import departmentAcsIcon from '@/assets/icons/department-acs.svg';
import productAndServicesIcon from '@/assets/icons/product-and-services.svg';
import projectAvsIcon from '@/assets/icons/project-acs.svg';

import expenseIcon from '@/assets/icons/expense.svg';
import revenueIcon from '@/assets/icons/revenue.svg';
import noInvoiceIcon from '@/assets/icons/no-invoice.svg';

import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

import { ChartConfig } from '@/components/ui/chart';
import PieGraph from '@/components/organisms/graphs/PieGraph';
import BudgetGeneralInfoAccordion from '@/components/molecules/BudgetGeneralInfoAccordion';
import OverviewList, { ListItem } from '@/components/organisms/OverviewList';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { useDeviceType } from '@/hooks/useDeviceType';

const bmevChartData = [
  { key: 'remaining', value: 77, fill: 'var(--color-remaining)' },
  { key: 'spent', value: 23, fill: 'var(--color-spent)' }
];

const btrtChartData = [
  { key: 'target', value: 77, fill: 'var(--color-target)' },
  { key: 'achieved', value: 45, fill: 'var(--color-achieved)' }
];

const bmevChartConfig = {
  remaining: {
    label: 'Remaining Budget',
    color: '#32ADE6'
  },
  spent: {
    label: 'Budget Spent',
    color: '#FF2D55'
  }
} satisfies ChartConfig;

const btrtChartConfig = {
  target: {
    label: 'Revenue Target',
    color: '#32ADE6'
  },
  achieved: {
    label: 'Achieved Revenue',
    color: '#34C759'
  }
} satisfies ChartConfig;

const bankAccounts: ListItem[] = [
  {
    id: '1',
    title: 'General Bank Account',
    icon: vaultIcon,
    data: [{ subtitle: 'Remaining balance', value: '160000' }]
  }
];

const accountingCenters: ListItem[] = [
  {
    id: '1',
    title: 'General Department Accounting Limited',
    icon: departmentAcsIcon,
    data: [
      { subtitle: 'Remaining ACMEV', value: '60000' },
      { subtitle: 'Achieved ACRT', value: '0' }
    ]
  },
  {
    id: '2',
    title: 'General Products and Services',
    icon: productAndServicesIcon,
    data: [
      { subtitle: 'Remaining ACMEV', value: '60000' },
      { subtitle: 'Achieved ACRT', value: '0' }
    ]
  },
  {
    id: '3',
    title: 'General Project Accountings',
    icon: projectAvsIcon,
    data: [
      { subtitle: 'Remaining ACMEV', value: '60000' },
      { subtitle: 'Achieved ACRT', value: '0' }
    ]
  }
];

const expenseAndRevenues: ListItem[] = [
  {
    id: '1',
    title: 'General Expense Categories',
    icon: expenseIcon,
    data: [{ subtitle: 'Remaining ACMEV', value: '60000' }]
  },
  {
    id: '2',
    title: 'General Revenue Categories',
    icon: revenueIcon,
    data: [{ subtitle: 'Remaining ACMEV', value: '60000' }]
  }
];

const DetailPage = () => {
  const [date, setDate] = useState<Date | undefined>(new Date());
  const { isMobileView } = useDeviceType();

  const handleAddBankClick = () => {};

  return (
    <>
      <MobileAppBar title="Budget Overview" onRightIconClick={() => {}} rightIcon={binIcon} />
      <div className="p-4">
        {/* BUDGET INFORMATION - MOBILE */}
        <div className="flex items-center justify-between gap-5 mb-6 md:hidden">
          <div className="flex items-center gap-3">
            <img src={budgetIcon} alt="budget-icon" width={40} height={40} />
            <div>
              <h4 className="font-semibold text-base">NUWBREEDING LTD</h4>
              <p className="text-secondary text-sm font-medium">Company</p>
            </div>
          </div>

          <button type="button">
            <img src={editIcon} alt="edit-icon" width={24} height={24} />
          </button>
        </div>

        <div className="md:flex md:gap-7">
          {/* BUDGET INFORMATION - WEB */}
          <div className="hidden md:flex p-5 border w-[60%] border-lightGray rounded-[16px] bg-white flex-col gap-[1.3rem]">
            <header className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <img src={budgetIcon} alt="budget-icon" width={40} height={40} />
                <div>
                  <h4 className="font-semibold text-base md:text-[24px] md:mb-[0.3rem]">NUWBREEDING LTD</h4>
                  <p className="text-secondary text-sm font-medium md:text-[18px]">Company</p>
                </div>
              </div>

              <div className="flex items-center gap-3">
                <button type="button">
                  <img src={editIcon} alt="edit-icon" width={28} height={28} />
                </button>
                <button type="button">
                  <img src={binIcon} alt="bin-icon" width={28} height={28} />
                </button>
              </div>
            </header>
            <div className="border border-primary rounded-[16px] p-4 bg-lightPurple flex-1 space-y-3">
              <p className="text-primary font-bold text-base mb-3">General Information</p>

              <div>
                <p className="text-sm text-secondary font-medium leading-[18px]">Description</p>
                <p className="text-base font-medium leading-[24.5px]">
                  Budget allocation for nuwbreeding company this year
                </p>
              </div>

              <div>
                <p className="text-sm text-secondary font-medium leading-[18px]">Default Currency Type</p>
                <p className="text-base font-medium leading-[24.5px]">Euro (€)</p>
              </div>

              <div className="flex gap-4">
                <div className="flex-1">
                  <p className="text-sm text-secondary font-medium leading-[18px]">
                    Budget maximum expense value (BMEV)
                  </p>
                  <p className="text-base font-medium leading-[24.5px]">€100,000</p>
                </div>

                <div className="flex-1">
                  <p className="text-sm text-secondary font-medium leading-[18px]">Alerting threshold of BMEV</p>
                  <p className="text-base font-medium leading-[24.5px]">80%</p>
                </div>
              </div>

              <div className="flex gap-4">
                <div className="flex-1">
                  <p className="text-sm text-secondary font-medium leading-[18px]">
                    Budget maximum expense value (BTRT)
                  </p>
                  <p className="text-base font-medium leading-[24.5px]">€100,000</p>
                </div>

                <div className="flex-1">
                  <p className="text-sm text-secondary font-medium leading-[18px]">Alerting threshold of BTRT</p>
                  <p className="text-base font-medium leading-[24.5px]">80%</p>
                </div>
              </div>

              <div className="flex gap-4">
                <div className="flex-1">
                  <p className="text-sm text-secondary font-medium leading-[18px]">Duration</p>
                  <p className="text-base font-medium leading-[24.5px]">01/01/2024 - 12/31/2024</p>
                </div>

                <div className="flex-1">
                  <p className="text-sm text-secondary font-medium leading-[18px]">Automatic Renewal</p>
                  <p className="text-base font-medium leading-[24.5px]">On</p>
                </div>
              </div>
            </div>
          </div>
          {/* GRAPH OVERVIEW */}
          <div className="md:w-[40%] md:bg-white md:p-[0.85rem] md:rounded-[16px]">
            <header className="w-full flex items-center justify-between mb-3 md:mb-4">
              <h3 className="text-base font-bold text-primary md:text-foreground">Graph Overview</h3>
              <Tabs defaultValue="percentage">
                <TabsList className="bg-lightPurple-200 h-auto py-[0.2rem]">
                  <TabsTrigger value="percentage" className="group md:py-[0.3rem]">
                    <img
                      src={percentageIcon}
                      alt="percentage-icon"
                      width={13}
                      height={17}
                      className="group-data-[state=active]:brightness-[100]"
                    />
                  </TabsTrigger>
                  <TabsTrigger value="euro" className="group md:py-[0.3rem]">
                    <img
                      src={euroIcon}
                      alt="euro-icon"
                      width={12}
                      height={12}
                      className="w-[12px] h-[12px] group-data-[state=active]:brightness-[100]"
                    />
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </header>
            <div className="flex items-start justify-between gap-3">
              <PieGraph
                title="Remaining BMEV"
                titleValue="100%"
                chartConfig={bmevChartConfig}
                chartData={bmevChartData}
                className="bg-white rounded-[16px] p-[0.85rem] flex-1 md:border border-lightGray md:py-10 md:flex md:items-center md:justify-center md:flex-col"
              />

              <PieGraph
                title="Achieved BTRT"
                titleValue="100%"
                chartConfig={btrtChartConfig}
                chartData={btrtChartData}
                variant="primary"
                className="bg-white rounded-[16px] p-[0.85rem] flex-1 md:border border-lightGray md:py-10 md:flex md:items-center md:justify-center md:flex-col"
              />
            </div>
          </div>
        </div>

        {/* GENERAL INFORMATION - MOBILE */}
        {isMobileView && <BudgetGeneralInfoAccordion />}

        <div className="md:flex md:items-stretch md:justify-between md:gap-6">
          {/* BANK ACCOUNTS */}
          <OverviewList
            title="Bank Accounts"
            items={bankAccounts}
            icon={vaultIcon}
            addButtonIcon={plusIcon}
            addButtonTitle="ADD BANK ACCOUNTS"
            valuePrefix="€"
            valueSuffix="k"
            onAddClick={handleAddBankClick}
            className="mt-8 bg-lightPurple rounded-[16px] md:flex-1 md:bg-white md:border md:border-lightGray"
          />

          {/* ACCOUNTING CENTERS */}
          <OverviewList
            title="Accounting Centers"
            items={accountingCenters}
            icon={buildingIcon}
            addButtonIcon={plusIcon}
            addButtonTitle="ADD ACCOUNTING CENTERS"
            valuePrefix="€"
            valueSuffix="k"
            onAddClick={handleAddBankClick}
            className="mt-8 bg-lightPurple rounded-[16px] md:flex-1 md:bg-white md:border md:border-lightGray"
          />

          {/* EXPENSE AND REVENUE */}
          <OverviewList
            title="Expense & Revenue"
            items={expenseAndRevenues}
            icon={vaultIcon}
            addButtonIcon={plusIcon}
            addButtonTitle="ADD BANK ACCOUNTS"
            valuePrefix="€"
            valueSuffix="k"
            onAddClick={handleAddBankClick}
            className="mt-8 bg-lightPurple rounded-[16px] md:flex-1 md:bg-white md:border md:border-lightGray"
          />
        </div>

        <div className="md:flex md:items-stretch md:justify-between md:gap-6">
          {/* INVOICE */}
          <section className="mt-7 md:flex-1 md:bg-white md:p-4 md:rounded-[16px] md:border md:border-lightGray md:flex md:flex-col">
            <p className="text-base font-bold">Invoice</p>
            <div className="bg-lightPurple rounded-[16px] p-6 flex items-center justify-center flex-col gap-3 mt-4 md:bg-white md:flex-1">
              <div className="flex flex-col items-center justify-center md:flex-row md:gap-4">
                <img src={noInvoiceIcon} alt="no invoice icon" className="md:w-[40px] md:h-[40px]" />
                <span className="mt-[3px] text-xs font-medium text-center leading-[16.5px] md:text-left">
                  <span className="md:text-base md:font-medium md:block mb-[-3px] md:text-foreground">
                    There are no Invoice to show.
                  </span>{' '}
                  <span className="md:text-secondary md:text-base md:font-medium">
                    {' '}
                    Please create a new invoice to allocate budget!
                  </span>
                </span>
              </div>
              <Button variant="primary-reverse" className="rounded-full font-semibold px-5 text-base">
                Create an Invoice
              </Button>
            </div>
          </section>

          {/* COMMITMENTS */}
          <div className="mt-7 md:bg-white md:p-4 md:rounded-[16px] md:border md:border-lightGray">
            <p className="text-base font-bold mb-4">Commitments</p>

            <Calendar
              mode="single"
              selected={date}
              onSelect={setDate}
              className="rounded-[16px] border border-lightGray py-6"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailPage;
