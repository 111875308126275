import MobileAppBar from '@/components/molecules/MobileAppBar';
import { Button } from '@/components/ui/button';
import React, { useState } from 'react';
import visaLgIcon from '@/assets/icons/visa-lg.svg';
import editIcon from '@/assets/icons/edit.svg';
import InfoModal from '@/components/molecules/InfoModal';
import { useDeviceType } from '@/hooks/useDeviceType';
import deleteIcon from '@/assets/icons/delete-account.svg';

const PaymentMethodPage = () => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const { isMobileView } = useDeviceType();

  const handleDelete = () => {};

  return (
    <>
      <MobileAppBar title="Payment Method" onRightIconClick={() => {}} rightIcon={editIcon} />
      <div className="p-4">
        <h5 className="text-base font-bold mb-4">Payment Method</h5>
        <div>
          <div className="flex items-center justify-center border bg-white rounded-[16px] p-6 py-[0.35rem] h-[165px]">
            <img src={visaLgIcon} alt="visa icon" />
          </div>
          <div className="mt-5 border bg-white rounded-[16px] p-6 py-[0.35rem]">
            <div className="border-b flex items-center justify-between gap-3 py-[0.65rem]">
              <p className="font-bold text-base">Name</p>
              <p className="text-base text-primary">Marco Bertolazzi</p>
            </div>
            <div className="border-b flex items-center justify-between gap-3 py-[0.65rem]">
              <p className="font-bold text-base"> Card Number</p>
              <p className="text-base flex items-center gap-[0.35rem]">**** **** **** 3256</p>
            </div>
            <div className="flex items-center justify-between gap-3 py-[0.65rem]">
              <p className="font-bold text-base">Exp</p>
              <p className="text-base">10/26</p>
            </div>
          </div>
          <Button
            onClick={() => {
              setIsDeleteOpen(true);
            }}
            variant={'text'}
            className="mt-10 block mx-auto text-lightRed font-semibold text-sm text-center"
          >
            Delete Payment Method
          </Button>
        </div>
        {/* <div className="h-full mt-14 flex flex-col gap-3 items-center justify-center">
          <span className="text-xs text-secondary">No Payment Method Added</span>
          <Button variant={'primary-reverse'} className="rounded-full px-6 text-base font-semibold">
            Add Payment Method
          </Button>
        </div> */}
        {isDeleteOpen && isMobileView && (
          <InfoModal
            isOpen={isDeleteOpen}
            variant="confirm"
            title="Are you sure want to Delete Payment Method?"
            message="You need to link another payment method after this"
            buttonText="Delete"
            onButtonClick={handleDelete}
            icon={deleteIcon}
            onClose={() => {
              setIsDeleteOpen(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default PaymentMethodPage;
