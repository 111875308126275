import React from 'react';

interface ProfileInfoCardProps {
  username: string;
  email: string;
  phone: string;
}

const ProfileInfoCard: React.FC<ProfileInfoCardProps> = ({ username, email, phone }) => {
  return (
    <div className="p-[1.2rem] py-[0.3rem] bg-white border rounded-[16px]">
      <div className="flex items-center justify-between gap-4 border-b py-4">
        <span className="font-bold text-base">Username</span>
        <p className="text-base">{username || '-'}</p>
      </div>
      <div className="flex items-center justify-between gap-4 border-b py-4">
        <span className="font-bold text-base">Mail</span>
        <p className="text-base">{email || '-'}</p>
      </div>
      <div className="flex items-center justify-between gap-4 py-4">
        <span className="font-bold text-base">Phone Number</span>
        <p className="text-base">{phone || '-'}</p>
      </div>
    </div>
  );
};

export default ProfileInfoCard;
