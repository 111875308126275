import { FieldError, FieldErrors, UseFormRegister } from 'react-hook-form';

import React from 'react';

type FormFieldError = FieldError | undefined | FieldErrors<any>;

interface InputFieldProps {
  name: string;
  label?: string;
  description?: string;
  type: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  register?: UseFormRegister<any>;
  error?: FormFieldError;
  className?: string;
  icon?: string;
  iconAlt?: string;
  size?: string;
}

const InputField: React.FC<InputFieldProps> = ({
  name,
  label,
  description,
  type,
  placeholder,
  value,
  onChange,
  disabled = false,
  register,
  error,
  className,
  icon,
  iconAlt = '',
  size = ''
}) => {
  const inputProps = register
    ? register(name)
    : {
        value: value || '',
        onChange: onChange
      };

  const errorMessage = error && 'message' in error ? error.message : '';

  return (
    <div className={`${className ? className : ''}`}>
      {label && (
        <label
          className={`inline-block text-[#495057] font-medium text-xs  ${size === 'sm' ? 'md:mb-[0.45rem] md:text-sm' : 'md:text-lg mb-2'}`}
        >
          {label}
        </label>
      )}
      <div className="relative">
        <input
          className={`bg-lightGray rounded-[20px] border w-full font-medium text-base p-[0.585rem] ${size === 'sm' ? 'md:p-[0.45rem] md:px-[0.875rem] md:text-base' : 'md:p-[1.215rem]'}  px-4
            outline-none placeholder:text-[#93999F] text-[#495057]
            disabled:cursor-not-allowed disabled:placeholder:text-[#93999f91]
            focus:outline-none focus:border-[#ADB5BD]
            ${error ? 'border-red-500' : ''}
            ${icon ? 'pr-12' : ''}  // Add extra padding when icon is present
          `}
          disabled={disabled}
          type={type}
          placeholder={placeholder}
          {...inputProps}
        />
        {icon && (
          <div className="absolute right-4 top-1/2 -translate-y-1/2">
            <img src={icon} alt={iconAlt} className="w-[12px] h-[16px]" />
          </div>
        )}
      </div>
      {(errorMessage || description) && (
        <p className={`mt-2 text-xs ${errorMessage ? 'text-red-600' : 'text-[#93999F]'}`}>
          {(errorMessage as String) || description}
        </p>
      )}
    </div>
  );
};

export default InputField;
