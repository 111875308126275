import React from 'react';
import { useLocation } from 'react-router-dom';

import WebSidebar from '@/components/organisms/WebSidebar';
import DashboardHeader from '@/components/organisms/DashboardHeader';
import { useDeviceType } from '@/hooks/useDeviceType';
import MobileBottonNavigation from '@/components/molecules/MobileBottonNavigation';

interface DashboardTemplateProps {
  children: React.ReactNode;
}

const DashboardTemplate: React.FC<DashboardTemplateProps> = ({ children }) => {
  const { isMobileView } = useDeviceType();
  const location = useLocation();

  // Function to check if current path is a sub-page
  const isSubPage = () => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    return pathSegments.length > 1; // Returns true if path has more than one segment
  };

  // Show bottom navigation only on mobile AND main pages
  const showBottomNav = isMobileView && !isSubPage();

  return (
    <div className="flex flex-col md:flex-row items-stretch h-screen bg-primary md:bg-transparent">
      {/* SIDEBAR NAVIGATION - WEB */}
      {!isMobileView && <WebSidebar />}
      <div
        className={`overflow-y-auto relative bg-[#FBFBFF] w-full flex flex-1 md:flex-auto md:flex md:flex-col md:rounded-none shadow-[0px_20px_20px_0px_#00000033] md:shadow-none  ${!showBottomNav ? '' : 'rounded-b-[36px]'}`}
      >
        {/* HEADER - WEB */}
        {!isMobileView && <DashboardHeader />}

        {/* MAIN */}
        <main className="flex-1 flex flex-col px-0 md:p-6 overflow-x-hidden">{children}</main>
      </div>
      {/* BOTTOM BAR NAVIGATION - MOBILE */}
      {showBottomNav && (
        <div className="h-[94px] px-6 flex items-center">
          <MobileBottonNavigation />
        </div>
      )}
    </div>
  );
};

export default DashboardTemplate;
