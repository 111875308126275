import React from 'react';
import visaIcon from '@/assets/icons/visa.svg';
import crownIcon from '@/assets/icons/crown.svg';

import { BillingRecord } from '@/types/billing';

type BillingHistoryCardProps = {
  record: BillingRecord;
};

const BillingHistoryCard: React.FC<BillingHistoryCardProps> = ({ record }) => {
  return (
    <div className="border bg-white rounded-[16px] p-6 py-[0.35rem]">
      <div className="border-b flex items-center justify-between gap-3 py-[0.65rem]">
        <p className="font-bold text-base">ID</p>
        <p className="text-base text-primary">{record?.id}</p>
      </div>
      <div className="border-b flex items-center justify-between gap-3 py-[0.65rem]">
        <p className="font-bold text-base">Payment Method</p>
        <p className="text-base flex items-center gap-[0.35rem]">
          <img src={visaIcon} alt="visa-icon" />
          ****{record?.paymentMethod?.lastFourDigits}{' '}
        </p>
      </div>
      <div className="border-b flex items-center justify-between gap-3 py-[0.65rem]">
        <p className="font-bold text-base">Issued Date</p>
        <p className="text-base">{record?.issuedDate}</p>
      </div>
      <div className="border-b flex items-center justify-between gap-3 py-[0.65rem]">
        <p className="font-bold text-base">Plan</p>
        <p className="text-base flex items-center gap-[0.35rem]">
          {record?.plan?.isPro && <img src={crownIcon} alt="crown-icon" />}

          {record?.plan?.name}
        </p>
      </div>
      <div className="flex items-center justify-between gap-3 py-[0.65rem]">
        <p className="font-bold text-base">Amount</p>
        <p className="text-base">{record?.amount?.currency + record?.amount?.value}</p>
      </div>
    </div>
  );
};

export default BillingHistoryCard;
