import MobileAppBar from '@/components/molecules/MobileAppBar';
import React from 'react';
import visaIcon from '@/assets/icons/visa.svg';
import crownIcon from '@/assets/icons/crown.svg';
import BillingHistoryList from '@/components/organisms/BillingHistoryList';
import { BillingRecord } from '@/types/billing';
import plusLgIcon from '@/assets/icons/plus-lg.svg';

const billingRecords: BillingRecord[] = [
  {
    id: '#527363839',
    paymentMethod: {
      type: 'visa' as const, // Using 'as const' to match the union type
      lastFourDigits: '3256'
    },
    issuedDate: '08/08/2024',
    plan: {
      name: 'Pro' as const, // Using 'as const' to match the union type
      isPro: true
    },
    amount: {
      value: 20,
      currency: '€'
    }
  },
  {
    id: '#527363838',
    paymentMethod: {
      type: 'visa' as const,
      lastFourDigits: '3256'
    },
    issuedDate: '07/08/2024',
    plan: {
      name: 'Standard' as const,
      isPro: false
    },
    amount: {
      value: 10,
      currency: '€'
    }
  }
];

const BillingHistoryPage = () => {
  return (
    <>
      <MobileAppBar title="Billing History" onRightIconClick={() => {}} rightIcon={plusLgIcon} />
      <section className="p-4 h-full">
        <h5 className="text-base font-bold mb-4">Recent Billing History</h5>
        <BillingHistoryList className="h-full space-y-5" billingRecords={billingRecords} />
      </section>
    </>
  );
};

export default BillingHistoryPage;
