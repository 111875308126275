import React from 'react';
import { Button } from '../ui/button';

import checkSuccessImg from '@/assets/images/check-success.svg';

type ModalVariant = 'success' | 'failure' | 'confirm';

interface InfoModalProps {
  isOpen: boolean;
  variant?: ModalVariant;
  title: string;
  message: string | React.ReactNode;
  buttonText: string | React.ReactNode;
  onButtonClick: () => void;
  icon?: string;
  onClose?: () => void;
}

const InfoModal: React.FC<InfoModalProps> = ({
  isOpen,
  variant = 'success',
  title,
  message,
  buttonText,
  onButtonClick,
  icon,
  onClose = () => {}
}) => {
  if (!isOpen) return null;

  // two variants for now
  const getDefaultIcon = (variant: ModalVariant) => {
    switch (variant) {
      case 'success':
        return checkSuccessImg;
      case 'failure':
        return checkSuccessImg;
      default:
        return checkSuccessImg;
    }
  };

  const getButtonColorClass = (variant: ModalVariant) => {
    switch (variant) {
      case 'success':
        return 'bg-primary hover:bg-primary-dark';
      case 'failure':
        return 'bg-red-500 hover:bg-red-600';
      default:
        return 'bg-primary hover:bg-primary-dark';
    }
  };

  return (
    <div className="fixed w-screen h-screen top-0 left-0 bg-[rgba(82,80,96,0.5)] backdrop-blur-[8px] z-50 flex items-center justify-center">
      <div className="bg-white rounded-[16px] overflow-hidden w-[327px]">
        <div className="w-full py-7 px-7 flex flex-col items-center justify-center gap-2">
          <img className="mb-3" src={icon || getDefaultIcon(variant)} alt={`${variant}-icon`} />
          <p className="font-bold text-xl text-center leading-[24.3px]">{title}</p>
          <p className="text-sm text-secondary text-center leading-[17px]">{message}</p>
        </div>
        <div className="flex">
          <Button
            onClick={() => {
              variant === 'confirm' ? onClose() : onButtonClick();
            }}
            className={`w-full text-base font-semibold h-[52px] md:h-[64px] md:text-[18px] ${getButtonColorClass(variant)} ${variant === 'confirm' ? 'rounded-none p-3 font-normal flex-1' : ''}`}
          >
            {variant === 'confirm' ? 'Cancel' : buttonText}
          </Button>
          <Button
            onClick={onButtonClick}
            className={`w-full text-base font-semibold h-[52px] md:h-[64px] md:text-[18px] bg-lightRed ${variant === 'confirm' ? 'rounded-none p-3 font-normal flex-1' : ''}`}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
