import MobileAppBar from '@/components/molecules/MobileAppBar';
import SubscriptionPlans from '@/components/molecules/SubscriptionPlans';
import { Button } from '@/components/ui/button';
import React, { useState } from 'react';

interface Plan {
  id: 'basic' | 'standard' | 'pro';
  name: string;
  price: string;
  benefits: string[];
}

const SubscriptionPage = () => {
  const [selectedPlan, setSelectedPlan] = useState<string>('basic');

  const plans: Plan[] = [
    {
      id: 'basic',
      name: 'Basic',
      price: 'Free',
      benefits: [
        'Maximum 2 Budgets',
        'Maximum 2 Bank Accounts for each Budget',
        'Maximum 6 Accounting Center for each Budget'
      ]
    },
    {
      id: 'standard',
      name: 'Standard',
      price: '€10',
      benefits: [
        'Able to Invite other user to join Budget',
        'Maximum 5 Budgets',
        'Unlimited number of Bank Account and Accounting Center'
      ]
    },
    {
      id: 'pro',
      name: 'Pro',
      price: '€20',
      benefits: [
        'Able to Invite other user to join Budget',
        'Unlimited number of Budget',
        'Unlimited number of Bank Account and Accounting Center'
      ]
    }
  ];
  const handlePlanSelect = (planId: string): void => {
    setSelectedPlan(planId);
  };
  return (
    <div className="pb-4">
      <MobileAppBar title="Subscription Plan" />
      <div className="p-4">
        <SubscriptionPlans
          plans={plans}
          selectedPlan={selectedPlan}
          onPlanSelect={handlePlanSelect}
          variant="profile"
        />
        <div className="border rounded-[16px] p-[0.9rem] py-3 mt-7">
          <span className="text-sm">Payment Method</span>
          <div className="flex items-center justify-between gap-3">
            <p className="text-secondary">No Payment Method added</p>
            <Button variant={'text'} className="text-sm font-semibold h-auto">
              + Add
            </Button>
          </div>
        </div>
        <div className="mt-7">
          <span className="inline-block text-xs font-medium text-gray mb-[0.6rem] leading-[14.7px]">
            by Clicking “Select Plan” button means you agree to billed annually.
          </span>
          <Button className="w-full rounded-full text-base uppercase font-semibold h-[44px]">Select Plan</Button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;
