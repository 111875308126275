import React from 'react';
import webSpriteSheet from '@/assets/icons/icons-sprite.svg';
import mobileSpriteSheet from '@/assets/icons/icons-sprite-mobile.svg';

import { WEB_ICON_POSITIONS, MOBILE_ICON_POSITIONS } from '@/constants/icons_sprite';
import { useDeviceType } from '@/hooks/useDeviceType';

interface IconPositions {
  [key: string]: {
    active: string;
    inactive: string;
  };
}

interface SidebarIconProps {
  iconKey: any;
  isActive: boolean;
}

const SidebarIcon = ({ iconKey, isActive }: SidebarIconProps) => {
  const { isMobileView } = useDeviceType();

  const spriteSheet = isMobileView ? mobileSpriteSheet : webSpriteSheet;
  const positions: IconPositions = isMobileView ? MOBILE_ICON_POSITIONS : WEB_ICON_POSITIONS;

  return (
    <figure
      className={`
        w-5 h-5 m-0
        md:w-[20px] md:h-[20px]
        bg-no-repeat
        ${isMobileView && isActive ? 'filter brightness-0 invert' : ''}
        ${isMobileView ? '!w-[32px] !h-[32px]' : ''}
      `}
      style={{
        backgroundImage: `url(${spriteSheet})`,
        backgroundPosition: positions[iconKey][isActive ? 'active' : 'inactive'] || '0 0'
      }}
    />
  );
};

export default SidebarIcon;
