import { Outlet } from 'react-router-dom';
import DashboardTemplate from '@/components/templates/DashboardTemplate';
import { useDeviceType } from '@/hooks/useDeviceType';
import SidebarLink from '@/components/molecules/SidebarLink';
import { useState } from 'react';

const ProfileLayout = () => {
  const [activeLink, setActiveLink] = useState('profile');

  const { isMobileView } = useDeviceType();

  const handleNavigation = (path: string) => {
    setActiveLink(path);
    // navigate(`/${path}`);
  };

  return (
    <DashboardTemplate>
      {isMobileView ? (
        <Outlet />
      ) : (
        <div className="h-full flex gap-6">
          <aside className="bg-white h-fit rounded-[16px] w-[250px] overflow-hidden pr-[2px]">
            <SidebarLink
              label="Profile"
              iconKey="dashboard"
              isActive={activeLink === 'profile'}
              onClick={() => handleNavigation('profile')}
            />
            <SidebarLink
              label="Subscription"
              iconKey="budget"
              isActive={activeLink === 'subscription'}
              onClick={() => handleNavigation('subscription')}
            />
            <SidebarLink
              label="Terminology"
              iconKey="invoice"
              isActive={activeLink === 'terminology'}
              onClick={() => handleNavigation('terminology')}
            />
          </aside>
          <div className=" bg-white rounded-[16px] p-4 px-5 flex-1 border overflow-y-auto">
            <Outlet />
          </div>
        </div>
      )}
    </DashboardTemplate>
  );
};

export default ProfileLayout;
