import React from 'react';

import { useNavigate } from 'react-router-dom';

import backIcon from '@/assets/icons/back.svg';

interface MobileAppBarProps {
  title: string;
  onBack?: () => void;
  onRightIconClick?: () => void;
  rightIcon?: string;
  variant?: string;
}

const MobileAppBar: React.FC<MobileAppBarProps> = ({
  title = '',
  onBack,
  onRightIconClick,
  rightIcon,
  variant = 'default'
}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (onBack) {
      onBack();
      return;
    }
    // navigate back
    navigate(-1);
  };

  return (
    <header
      className={`z-20 flex items-center justify-between sticky top-0 left-0 bg-white px-5 py-3 h-[56px] md:hidden ${variant === 'primary' ? 'border-b' : ''}`}
    >
      {variant === 'default' ? (
        <button onClick={handleBack} className="text-foreground flex items-center gap-1">
          {' '}
          <img src={backIcon} alt="back-icon" /> <span className="text-xs font-medium">{title}</span>
        </button>
      ) : (
        <p className="text-primary text-[28px] font-bold uppercase">{title}</p>
      )}

      {onRightIconClick && (
        <button
          onClick={() => {
            if (onRightIconClick) {
              onRightIconClick();
            }
          }}
          className="text-foreground flex items-center gap-1"
        >
          {' '}
          <img src={rightIcon} alt="icon" />
        </button>
      )}
    </header>
  );
};

export default MobileAppBar;
