import React from 'react';
import { useNavigate } from 'react-router-dom';
import defaultUserImage from '@/assets/images/default-user.png';
import chevronDownIcon from '@/assets/icons/chevron-down.svg';
import { authService } from '@/services/auth.service';
import toast from 'react-hot-toast';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';

interface ProfileDropdownProps {
  avatar?: string;
  name: string;
}

const ProfileDropdown: React.FC<ProfileDropdownProps> = ({ avatar, name = 'No name' }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await authService.logout();
      navigate('/login'); // redirecting to login page after logout
    } catch (error) {
      toast.error('Logout failed. Please try again');
    }
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className="cursor-pointer flex items-center gap-[0.875rem]">
          <img width={38} height={38} src={avatar || defaultUserImage} alt="User Avatar" />
          <p className="text-base font-semibold">{name}</p>
          <span className="bg-[#F3F4F6] w-[20px] h-[20px] rounded-full flex items-center justify-center">
            <img src={chevronDownIcon} alt="Chevron down" />
          </span>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-full border" align="end">
        <DropdownMenuItem onClick={handleProfileClick} className="cursor-pointer font-medium">
          Profile
        </DropdownMenuItem>
        <DropdownMenuItem className="cursor-pointer font-medium">Settings</DropdownMenuItem>
        <DropdownMenuItem className="cursor-pointer font-medium text-red-500" onClick={handleLogout}>
          Logout
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ProfileDropdown;
