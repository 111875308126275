import MobileAppBar from '@/components/molecules/MobileAppBar';
import DashboardTemplate from '@/components/templates/DashboardTemplate';
import React from 'react';
import searchIcon from '@/assets/icons/search.svg';

const InvoicePage = () => {
  return (
    <DashboardTemplate>
      <MobileAppBar title="Invoice" variant="primary" onRightIconClick={() => {}} rightIcon={searchIcon} />
      <div className="p-5 flex items-end pb-8">Invoice Page</div>
    </DashboardTemplate>
  );
};

export default InvoicePage;
