import MobileAppBar from '@/components/molecules/MobileAppBar';
import DashboardTemplate from '@/components/templates/DashboardTemplate';
import React from 'react';
import searchIcon from '@/assets/icons/search.svg';

import { Outlet, useLocation } from 'react-router-dom';

const BudgetPage = () => {
  const location = useLocation();

  if (location.pathname === '/budget') {
    return (
      <DashboardTemplate>
        <MobileAppBar title="Budget" variant="primary" onRightIconClick={() => {}} rightIcon={searchIcon} />
        <div className="p-5">Budget Page</div>
      </DashboardTemplate>
    );
  }

  return (
    <DashboardTemplate>
      {' '}
      <Outlet />
    </DashboardTemplate>
  );
};

export default BudgetPage;
