import React, { useState } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import SwitchField from '@/components/atoms/SwitchField';
import calendarIcon from '@/assets/icons/calender-2.svg';
import chevronPrimaryRoundedIcon from '@/assets/icons/chevron-rounded-primary-down.svg';

const BudgetGeneralInfoAccordion = () => {
  const [isAutoRenewal, setIsAutoRenewal] = useState(true);

  return (
    <Accordion defaultValue="item-1" type="single" collapsible className="mt-8">
      <AccordionItem value="item-1" className="bg-lightPurple rounded-[16px] overflow-hidden">
        <AccordionTrigger
          icon={<img src={chevronPrimaryRoundedIcon} alt="chevron icon" />}
          className="border-b border-lightPurple-200 py-0 min-h-[52px] px-4"
        >
          <div>
            <p className="text-primary font-bold text-base">General Information</p>
            {/* <span className="inline-block relative w-[16px] h-[16px] bg-lightPurple-200 rounded-full">
                  <img
                    className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rotate-[180deg] h-[5.5px] w-[8.2px]"
                    src={chevronPrimaryIcon}
                    alt="chevron icon"
                  />
                </span> */}
          </div>
        </AccordionTrigger>
        <AccordionContent className="p-4 space-y-[1.1rem]">
          <div>
            <span className="text-[11px] text-lightPurple-300 font-medium">Description</span>
            <p className="text-sm font-medium leading-[17px]">Budget allocation for nuwbreeding company this year</p>
          </div>

          <div>
            <span className="text-[11px] text-lightPurple-300 font-medium">Default Currency Type</span>
            <p className="text-sm font-medium leading-[17px]">Euro (€)</p>
          </div>

          <div>
            <span className="text-[11px] text-lightPurple-300 font-medium">Budget maximum expense value (BMEV)</span>
            <p className="text-sm font-medium leading-[17px]">€100,000</p>
          </div>

          <div>
            <span className="text-[11px] text-lightPurple-300 font-medium">Alerting threshold of BMEV</span>
            <p className="text-sm font-medium leading-[17px]">80%</p>
          </div>

          <div>
            <span className="text-[11px] text-lightPurple-300 font-medium">Budget total revenue target (BTRT)</span>
            <p className="text-sm font-medium leading-[17px]">€500,000</p>
          </div>

          <div>
            <span className="text-[11px] text-lightPurple-300 font-medium">Alerting threshold of BTRT</span>
            <p className="text-sm font-medium leading-[17px]">80%</p>
          </div>

          <div>
            <SwitchField
              name="autoRenewal"
              label="Automatic Renewal"
              checked={isAutoRenewal}
              onCheckedChange={(checked) => setIsAutoRenewal(checked)}
              className="flex-1"
            />
            {isAutoRenewal && (
              <div className="bg-lightPurple-200 rounded-[12px] p-2 px-[0.625rem] mt-[1.1rem] flex items-center gap-3">
                <img src={calendarIcon} alt="calendar icon" />
                <p className="text-sm font-bold">01/01/2024 - 12/31/2024</p>
              </div>
            )}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default BudgetGeneralInfoAccordion;
