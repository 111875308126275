import isMobile from 'is-mobile';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import React, { useEffect, useState } from 'react';
import { userService } from '@/services/user.service';
import { useNavigate } from 'react-router-dom';

import SuccessMessage from '@/components/molecules/SuccessMessage';
import toast from 'react-hot-toast';
import { useWidth } from '@/hooks/useWidth';

import cameraIcon from '@/assets/icons/camera.svg';
import defaultUserImg from '@/assets/images/default-user.png';

import InputField from '../atoms/InputField';
import InfoModal from '../molecules/InfoModal';
import UserProfilePicInput from '../molecules/UserProfilePicInput';
import { Button } from '../ui/button';
import { useAuth } from '@/hooks/useAuth';

import { zodResolver } from '@hookform/resolvers/zod';

const createProfileSchema = z.object({
  firstName: z.string().min(2, 'First name is required'),
  lastName: z.string().min(2, 'Last name is required'),
  username: z.string().min(1, 'Username is required'),
  email: z.string().email(),
  phone: z.string().regex(/^\+[1-9]\d{1,14}$/, {
    message: 'Phone number must be in E.164 format: +[country code][number]'
  })
});

const editProfileSchema = z.object({
  firstName: z.string().min(2, 'Must be greater than 2').optional(),
  lastName: z.string().min(2, 'Must be greater than 2').optional(),
  username: z.string().optional(),
  email: z.string().email(),
  phone: z
    .string()
    .regex(/^\+[1-9]\d{1,14}$/, {
      message: 'Phone number must be in E.164 format: +[country code][number]'
    })
    .optional()
});

type ProfileFormData = z.infer<typeof editProfileSchema>;

const ProfileCreationForm = ({ variant = 'default', size = '' }: any) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const navigate = useNavigate();
  const { user } = useAuth();
  const width = useWidth();
  const isMobileView = width < 768 || isMobile() ? true : false;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<ProfileFormData>({
    resolver: zodResolver(variant === 'edit' ? editProfileSchema : createProfileSchema),
    defaultValues: {
      email: ''
    }
  });

  // update email when user data becomes available
  useEffect(() => {
    if (user?.email) {
      setValue('email', user.email);
    }
  }, [user, setValue]);

  const handleImageChange = (file: File | null) => {
    setSelectedImage(file);
  };

  const handleImageRemove = () => {
    setSelectedImage(null);
  };

  const handleOnBoardingClick = () => {
    if (variant === 'edit') navigate('/profile');
    else navigate('/onboarding/overview');
  };

  const onSubmit = async (data: any) => {
    try {
      if (variant === 'edit') {
        // await userService.updateProfile({
        //   firstName: data.firstName,
        //   lastName: data.lastName,
        //   phoneNumber: data.phone,
        //   profile_pic: selectedImage || undefined
        // });
        toast.success('Profile updated successfully!');
        handleOnBoardingClick();
      } else {
        await userService.createProfile({
          firstName: data.firstName,
          lastName: data.lastName,
          username: data.username,
          email: data.email,
          phoneNumber: data.phone,
          profile_pic: selectedImage || undefined
        });
        toast.success('Profile created successfully!');
        setShowSuccess(true);
        handleOnBoardingClick();
      }
    } catch (error) {
      console.error(`Failed to ${variant === 'edit' ? 'update' : 'create'} profile:`, error);
      toast.error(`Failed to ${variant === 'edit' ? 'update' : 'create'} profile. Please try again.`);
    }
  };

  return (
    <div
      className={`w-full ${variant === 'default' ? 'pb-10' : 'pb-0'}  flex-1 h-full mx-auto laptop-h:h-fit ${showSuccess ? 'flex flex-col justify-between' : ''}`}
    >
      {(!showSuccess || isMobileView) && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`px-4 md:px-0 flex flex-col justify-center items-center md:items-start ${size === 'sm' ? 'md:mt-0 md:pt-0' : 'md:mt-4'} py-3`}
        >
          <UserProfilePicInput
            defaultUserImg={defaultUserImg}
            cameraIcon={cameraIcon}
            onImageChange={handleImageChange}
            onImageRemove={handleImageRemove}
          />
          <div className="w-full mt-6 flex items-start gap-4">
            <InputField
              label={'Firstname'}
              placeholder="Firstname..."
              name="firstName"
              type="text"
              register={register}
              className="flex-1"
              error={errors.firstName}
              size={!isMobileView ? 'sm' : ''}
            />
            <InputField
              label={'Lastname'}
              placeholder="Lastname..."
              name="lastName"
              type="text"
              register={register}
              className="flex-1"
              error={errors.lastName}
              size={!isMobileView ? 'sm' : ''}
            />
          </div>
          <div className="w-full flex flex-col items-start gap-x-4 md:flex-row">
            <InputField
              className="w-full mt-5"
              label={'Username'}
              placeholder="Username..."
              name="username"
              type="text"
              register={register}
              error={errors.username}
              disabled={variant === 'edit'}
              size={!isMobileView ? 'sm' : ''}
            />
            <InputField
              className="w-full mt-5"
              label={'Mail'}
              placeholder="Mail..."
              name="email"
              type="text"
              register={register}
              disabled
              size={!isMobileView ? 'sm' : ''}
            />
          </div>
          <div className="w-full flex flex-col items-start gap-x-4 md:flex-row">
            <InputField
              className="w-full mt-5 md:w-1/2 md:ml-0 md:mr-auto"
              label={'Phone Number'}
              placeholder="Enter phone number..."
              name="phone"
              type="text"
              register={register}
              error={errors.phone}
              size={!isMobileView ? 'sm' : ''}
            />
            <span></span>
          </div>

          <div
            className={`flex items-center flex-col md:flex-row-reverse w-full ${size === 'sm' ? 'justify-start gap-6 md:mt-[55.4px]' : 'justify-between md:mt-[86.4px]'}  `}
          >
            <Button
              type="submit"
              className={`mt-[86.4px] mb-5 w-full rounded-[1.5rem] text-base uppercase font-semibold h-[2.75rem] md:mb-0 md:mt-0  md:w-fit md:px-7 ${size === 'sm' ? 'md:h-[40px] md:text-[1rem] capitalize' : 'md:h-[4rem] md:text-[1.125rem]'}`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'PROCESSING...' : variant === 'default' ? 'CREATE AN ACCOUNT' : 'Save Changes'}
            </Button>
            <button
              disabled={isSubmitting}
              type="button"
              className={` font-semibold  md:mt-2 w-fit ${size === 'sm' ? 'text-lightRed' : 'text-primary'}`}
              onClick={handleOnBoardingClick}
            >
              {variant === 'default' ? 'Skip for now' : 'Cancel'}
            </button>
          </div>
        </form>
      )}

      {showSuccess && !isMobileView && (
        <>
          <SuccessMessage title="Account Created" description="You have successfully created an account in BatJet!" />

          <Button
            onClick={handleOnBoardingClick}
            className="mt-[86.4px] mb-5 w-fit rounded-[1.5rem] text-base font-semibold px-6 h-[4rem] text-[1.125rem] ml-auto mr-0"
          >
            GO TO ONBOARDING
          </Button>
        </>
      )}

      {showSuccess && isMobileView && (
        <InfoModal
          isOpen={showSuccess}
          variant="success"
          title="Account Created"
          message="You have successfully created an account in BatJet!"
          buttonText="Go to Onboarding"
          onButtonClick={handleOnBoardingClick}
        />
      )}
    </div>
  );
};

export default ProfileCreationForm;
