import React from 'react';
import { Button } from '@/components/ui/button';
import crownIcon from '@/assets/icons/crown.svg';
import { useNavigate } from 'react-router-dom';

interface SubscriptionPlanCardProps {
  plan: string;
  nextPaymentDate?: string;
}

const SubscriptionPlanCard: React.FC<SubscriptionPlanCardProps> = ({ plan, nextPaymentDate }) => {
  const isBasic = plan === 'basic';
  let amount;

  const navigate = useNavigate();

  if (isBasic) {
    amount = 'FREE';
  } else if (plan === 'standard') {
    amount = '€10';
  } else {
    amount = '€20';
  }
  return (
    <div className="border-2 border-primary p-[0.4rem] px-[0.9rem] pb-[0.6rem] rounded-[16px] bg-lightPurple">
      <div className="mt-1 flex items-center justify-between gap-4">
        <div className="flex flex-col">
          <span className="text-xs font-medium mb-[0.35rem]">Subscription Plan</span>
          <span className="font-medium text-base capitalize flex gap-[3px]">
            {!isBasic && <img src={crownIcon} alt="crown" className="mb-[3px]" />}
            {plan}
          </span>
          <p className="text-2xl font-bold uppercase leading-[22px] mb-[0.385rem]">
            {amount}
            {!isBasic && <span className="text-base font-normal capitalize ml-[3px]">/Month</span>}
          </p>
          {!isBasic && <p className="text-xs font-medium">Your next payment 09/08/2024</p>}
        </div>
        <Button
          onClick={() => navigate('/profile/subscription')}
          variant={'text'}
          className="p-0 text-sm font-semibold h-auto"
        >
          Change
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionPlanCard;
