import React from 'react';
import SubscriptionPlanBox from '@/components/molecules/SubscriptionPlanBox';
import iconCheck from '@/assets/icons/check.svg';

interface Plan {
  id: 'basic' | 'standard' | 'pro';
  name: string;
  price: string;
  benefits: string[];
}

interface SubscriptionPlansProps {
  plans: Plan[];
  selectedPlan: string;
  onPlanSelect: (planId: string) => void;
  variant?: 'onboarding' | 'profile';
}

const SubscriptionPlans: React.FC<SubscriptionPlansProps> = ({
  plans,
  selectedPlan,
  onPlanSelect,
  variant = 'onboarding'
}) => {
  const selectedPlanBenefits = plans.find((item) => item?.id === selectedPlan)?.benefits || [];

  return (
    <div className="h-full md:flex-1 md:p-0 md:flex md:flex-col">
      <p className="text-base font-bold mb-[0.125rem] md:hidden">Select Subscription Plan</p>
      {variant === 'onboarding' && (
        <p className="text-sm text-gray md:text-lg md:text-secondary md:mt-2">
          <span className="hidden md:inline-block">*</span> You can change it later on profile page
        </p>
      )}

      <div className="flex-1 mt-6 mb-[1.7rem] flex flex-col gap-4 md:flex-row">
        {plans.map((plan) => (
          <SubscriptionPlanBox key={plan.id} plan={plan} isActive={selectedPlan === plan.id} onSelect={onPlanSelect} />
        ))}
      </div>

      <div className="md:hidden">
        <p className="text-sm">Benefit</p>
        <ul className="mt-[0.9rem] flex flex-col gap-4">
          {selectedPlanBenefits?.length > 0 &&
            selectedPlanBenefits?.map((item) => (
              <li key={item} className="flex items-start gap-[0.6rem]">
                <span className="flex items-center justify-center rounded-full bg-lightPurple w-[20px] h-[20px]">
                  <img src={iconCheck} alt="check-icon" />
                </span>
                <p className="leading-[19.5px]">{item}</p>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default SubscriptionPlans;
