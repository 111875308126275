import MobileAppBar from '@/components/molecules/MobileAppBar';
import DashboardTemplate from '@/components/templates/DashboardTemplate';
import React from 'react';
import searchIcon from '@/assets/icons/search.svg';

const CommitmentPage = () => {
  return (
    <DashboardTemplate>
      <MobileAppBar title="Commitment" variant="primary" onRightIconClick={() => {}} rightIcon={searchIcon} />
      <div className="p-5 flex items-end pb-8">Commitment Page</div>
    </DashboardTemplate>
  );
};

export default CommitmentPage;
