import MobileAppBar from '@/components/molecules/MobileAppBar';
import DashboardTemplate from '@/components/templates/DashboardTemplate';
import React, { useState } from 'react';
import editIcon from '@/assets/icons/edit.svg';
import chevronRightIcon from '@/assets/icons/chevron-right.svg';
import { Button } from '@/components/ui/button';
import { authService } from '@/services/auth.service';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import defaultAvatar from '@/assets/images/default-user.png';
import ProfileName from '@/components/molecules/ProfileName';
import ProfileInfoCard from '@/components/molecules/ProfileInfoCard';
import SubscriptionPlanCard from '@/components/molecules/SubscriptionPlanCard';
import InfoModal from '@/components/molecules/InfoModal';
import { useDeviceType } from '@/hooks/useDeviceType';
import logoutIcon from '@/assets/icons/logout.svg';
import deleteIcon from '@/assets/icons/delete-account.svg';
import { PAGE } from '@/routes';
import SidebarLink from '@/components/molecules/SidebarLink';
import logoutRedIcon from '@/assets/icons/logout-red.svg';
import UserProfilePicInput from '@/components/molecules/UserProfilePicInput';
import cameraIcon from '@/assets/icons/camera.svg';
import InputField from '@/components/atoms/InputField';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';

const editProfileSchema = z.object({
  firstName: z.string().min(2, 'Must be greater than 2').optional(),
  lastName: z.string().min(2, 'Must be greater than 2').optional(),
  username: z.string().optional(),
  email: z.string().email(),
  phone: z
    .string()
    .regex(/^\+[1-9]\d{1,14}$/, {
      message: 'Phone number must be in E.164 format: +[country code][number]'
    })
    .optional()
});

type ProfileFormData = z.infer<typeof editProfileSchema>;

const ProfilePage = () => {
  const [isLogoutOpen, setIsLogoutOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('profile');
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [isEdit, setIsEdit] = useState(false);

  const { isMobileView } = useDeviceType();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<ProfileFormData>({
    resolver: zodResolver(editProfileSchema),
    defaultValues: {
      email: ''
    }
  });

  const handleLogout = async () => {
    try {
      await authService.logout();
      navigate('/login'); // redirecting to login page after logout
    } catch (error) {
      toast.error('Logout failed. Please try again');
    }
  };

  const handleDelete = () => {};

  const handleEditClick = () => {
    navigate(PAGE.PROFILE.children.EDIT);
  };

  const handleBillingHistoryClick = () => {
    navigate(PAGE.PROFILE.children.BILLING_HISTORY);
  };

  const handlePaymentMethodClick = () => {
    navigate(PAGE.PROFILE.children.PAYMENT_METHOD);
  };

  const handleTerminologyClick = () => {
    navigate(PAGE.PROFILE.children.TERMINOLOGY);
  };

  const handleNavigation = (path: string) => {
    setActiveLink(path);
    // navigate(`/${path}`);
  };

  const handleImageChange = (file: File | null) => {
    setSelectedImage(file);
  };

  const handleImageRemove = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <MobileAppBar title="Profile" variant="primary" onRightIconClick={handleEditClick} rightIcon={editIcon} />

      {isMobileView ? (
        <div className="p-5 py-4 flex-1 flex flex-col pb-8 gap-6">
          {/* PROFILE NAME */}
          <ProfileName profilePic={defaultAvatar} name="marco BERTOLAZZI" />

          {/* PROFILE INFO */}
          <ProfileInfoCard username="marcobertolazzi" email="marcoberto@mail.com" phone="+93912345678" />

          {/* SUBSCRIPTION INFO */}
          <SubscriptionPlanCard plan="pro" />

          {/* PAYMENT & BILLING */}
          <div className="p-[1.2rem] py-[0.3rem] bg-white border rounded-[16px]">
            <button
              onClick={handlePaymentMethodClick}
              className="w-full flex items-center justify-between gap-4 border-b py-4"
            >
              <span className="font-bold text-sm">Payment Method</span>
              <img src={chevronRightIcon} alt="chevron-right" />
            </button>
            <button
              onClick={handleBillingHistoryClick}
              className="w-full flex items-center justify-between gap-4 border-b py-4"
            >
              <span className="font-bold text-sm">Billing History</span>
              <img src={chevronRightIcon} alt="chevron-right" />
            </button>
            <button onClick={handleTerminologyClick} className="w-full flex items-center justify-between gap-4 py-4">
              <span className="font-bold text-sm">Terminology</span>
              <img src={chevronRightIcon} alt="chevron-right" />
            </button>
          </div>

          <div className="flex flex-col items-center gap-2">
            <Button
              onClick={() => {
                setIsLogoutOpen(true);
              }}
              type="button"
              variant={'primary-reverse'}
              className="w-full rounded-full text-base font-semibold"
            >
              Logout
            </Button>
            <Button
              onClick={() => {
                setIsDeleteOpen(true);
              }}
              variant={'text'}
              className="mx-auto w-fit text-[#E8507E] h-auto"
            >
              Delete Account
            </Button>
          </div>

          {isLogoutOpen && isMobileView && (
            <InfoModal
              isOpen={isLogoutOpen}
              variant="confirm"
              title="Are you sure want to Logout?"
              message="You’ll automatically signed out from BatJet"
              buttonText="Logout"
              onButtonClick={handleLogout}
              icon={logoutIcon}
              onClose={() => {
                setIsLogoutOpen(false);
              }}
            />
          )}

          {isDeleteOpen && isMobileView && (
            <InfoModal
              isOpen={isDeleteOpen}
              variant="confirm"
              title="Are you sure want to Delete this Account?"
              message="All Budget will removed permanently and unable to restore. You’ll automatically signed out"
              buttonText="Delete"
              onButtonClick={handleDelete}
              icon={deleteIcon}
              onClose={() => {
                setIsDeleteOpen(false);
              }}
            />
          )}
        </div>
      ) : (
        <div className="h-full flex flex-col justify-between">
          <div className=" space-y-4">
            <div>
              <span className="inline-block text-sm font-medium text-gray mb-4">Profile Picture</span>
              <img src={defaultAvatar} alt="" width={120} height={120} />
            </div>
            <div>
              <span className="text-sm font-medium text-gray">Username</span>
              <p className="text-base font-medium leading-[19.5px]">marcobertolazzi</p>
            </div>
            <div className="flex items-start gap-4">
              <div className="flex-1">
                <span className="text-sm font-medium text-gray">First name</span>
                <p className="text-base font-medium leading-[19.5px]">Marci</p>
              </div>
              <div className="flex-1">
                <span className="text-sm font-medium text-gray">Last name</span>
                <p className="text-base font-medium leading-[19.5px]">Bertolazzi</p>
              </div>
            </div>
            <div className="flex items-start gap-4">
              <div className="flex-1">
                <span className="text-sm font-medium text-gray">Mail</span>
                <p className="text-base font-medium leading-[19.5px]">marcoberto@mail.com</p>
              </div>
              <div className="flex-1">
                <span className="text-sm font-medium text-gray">Phone Number</span>
                <p className="text-base font-medium leading-[19.5px]">+93912345678</p>
              </div>
            </div>
          </div>
          <footer className="mt-auto mb-3 flex items-center justify-between">
            <Button onClick={handleLogout} variant={'text'} className="h-0 m-0 p-0 text-lightRed font-semibold gap-2">
              <img src={logoutRedIcon} alt="" />
              <span>Logout</span>
            </Button>
            <div className="space-x-5">
              <Button variant={'text'} className="h-0 m-0 p-0 text-base text-lightRed font-semibold gap-2">
                Delete Account
              </Button>
              <Button
                onClick={handleEditClick}
                variant={'primary-reverse'}
                className="text-base px-5 rounded-full font-semibold gap-2"
              >
                Edit Profile
              </Button>
            </div>
          </footer>
        </div>
      )}
    </>
  );
};

export default ProfilePage;
