import { useForm } from 'react-hook-form';

import MobileAppBar from '@/components/molecules/MobileAppBar';
import BudgetCreationForm from '@/components/organisms/BudgetCreationForm';
import { Button } from '@/components/ui/button';
import gearIcon from '@/assets/icons/gear.svg';

const AddPage = () => {
  return (
    <>
      <MobileAppBar title="Budget Creation" onRightIconClick={() => {}} rightIcon={gearIcon} />

      <div className="bg-[#FBFBFF] p-4 md:bg-white">
        <h2 className="text-center font-bold mb-5 md:text-left md:text-[24px] md:mb-6">CREATE YOUR BUDGET</h2>

        {/* BUDGET CREATION FORM */}
        <BudgetCreationForm />
      </div>

      {/* FOOTER */}
      <footer className="px-4 py-7 bg-gradient-to-b from-[#F0EFFD]/0 from-0% to-[#F0EFFD] to-[17.74%] md:bg-white md:bg-none md:from-transparent md:to-transparent md:flex md:justify-end">
        <Button
          className="hidden md:block rounded-[24px] text-base font-semibold px-6 h-[44px] text-[16px] w-full md:w-fit text-[#E8507E]"
          variant={'text'}
        >
          CANCEL
        </Button>
        <Button className="rounded-[24px] text-base font-semibold px-6 h-[44px] text-[16px] w-full md:w-fit">
          CREATE
        </Button>
      </footer>
    </>
  );
};

export default AddPage;
