import React, { KeyboardEvent, useState } from 'react';
import crossIcon from '@/assets/icons/cross.svg';

interface SearchInputFieldProps {
  placeholder?: string;
  onSearch: (searchTerm: string) => void;
  className?: string;
  searchIcon: string;
}

const SearchInputField: React.FC<SearchInputFieldProps> = ({
  placeholder = 'Search for Budgets, Accounts, etc...',
  onSearch,
  className = '',
  searchIcon
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    onSearch(searchTerm.trim());
  };

  const handleClear = () => {
    setSearchTerm('');
    onSearch(''); // Notify parent component about cleared search
  };

  return (
    <div className={`relative flex bg-lightGray rounded-[16px] overflow-hidden h-[40px] w-full ${className}`}>
      <img className="absolute top-[50%] translate-y-[-50%] left-[15px]" src={searchIcon} alt="search icon" />

      <input
        className="bg-transparent text-xs font-medium w-full placeholder:text-secondary placeholder:text-xs placeholder:font-medium pl-[43px] pr-[40px] rounded-[16px] [&::-webkit-search-cancel-button]:hidden [&::-webkit-search-decoration]:hidden"
        type="search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={handleKeyPress}
        placeholder={placeholder}
      />

      {searchTerm && (
        <button
          tabIndex={0}
          type="button"
          onClick={handleClear}
          className="absolute top-[50%] translate-y-[-50%] right-[15px] p-2 hover:opacity-80 transition-opacity"
          aria-label="Clear search"
        >
          <img src={crossIcon} alt="Clear search" />
        </button>
      )}
    </div>
  );
};

export default SearchInputField;
