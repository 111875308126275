import BillingHistoryCard from '@/components/molecules/BillingHistoryCard';
import React from 'react';

import { BillingRecord } from '@/types/billing';

type BillingHistoryProps = {
  billingRecords: BillingRecord[];
  className?: string;
};

const BillingHistoryList: React.FC<BillingHistoryProps> = ({ billingRecords = [], className = '' }) => {
  const renderBillingHistoryList = () => {
    if (billingRecords.length === 0) {
      return (
        <p className="mt-20 text-sm font-medium text-center">
          There are no Billing to show <br></br>{' '}
          <span className="text-sm font-medium text-secondary">
            Buy Pro plan and view your transaction history here!
          </span>
        </p>
      );
    }

    return billingRecords?.map((record) => <BillingHistoryCard key={record.id} record={record} />);
  };

  return <div className={className}>{renderBillingHistoryList()}</div>;
};

export default BillingHistoryList;
