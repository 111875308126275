import MobileAppBar from '@/components/molecules/MobileAppBar';
import TermsCard from '@/components/molecules/TermsCard';
import React, { useEffect, useState } from 'react';
import searchIcon from '@/assets/icons/search.svg';
import SearchInputField from '@/components/molecules/SearchInputField';

const TerminologyPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const handleSearch = (term: string) => {
    setSearchQuery(term);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MobileAppBar title="Terminology" />
      <div className="bg-white p-0">
        {/* SEARCH */}
        <div className="p-4 py-6 border-b">
          <SearchInputField searchIcon={searchIcon} onSearch={handleSearch} placeholder="Search terminology..." />
        </div>

        <TermsCard className="p-4 py-6" searchTerm={searchQuery} />
      </div>
    </div>
  );
};

export default TerminologyPage;
