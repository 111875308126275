import MobileAppBar from '@/components/molecules/MobileAppBar';
import ProfileCreationForm from '@/components/organisms/ProfileCreationForm';

const EditProfilePage = () => {
  return (
    <div className="pb-5">
      <MobileAppBar title="Edit Profile" />
      <ProfileCreationForm variant="edit" size="sm" />
    </div>
  );
};

export default EditProfilePage;
