import { terms } from '@/utils/constants';
import React from 'react';

interface TermsCardProps {
  className?: string;
  searchTerm?: string;
}

const TermsCard: React.FC<TermsCardProps> = ({ className, searchTerm = '' }) => {
  const highlightText = (text: string) => {
    if (!searchTerm) return text;

    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <span key={index} className="bg-[#BFB4FF] p-[0.1rem] px-[0.285rem] rounded-md">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <article className={`${className} flex flex-col gap-6`}>
      {terms?.map((item, index) => (
        <div key={index} className="flex flex-col gap-[0.2rem]">
          <h4 className="leading-[25px] md:text-lg md:font-bold">{highlightText(item.title)}</h4>
          <p className="text-sm text-secondary leading-[19.5px] md:text-base">{highlightText(item.description)}</p>
        </div>
      ))}
    </article>
  );
};

export default TermsCard;
